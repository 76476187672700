<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">

        <v-card-title class="d-flex align-center justify-center py-7">
        <router-link
          to="/"
          class="brand-logo d-flex align-center"
        >
          <v-img
            :src="appLogo"
            max-height="230px"
            max-width="230px"
            alt="logo"
            contain
            class="me-3 "
          ></v-img>

        </router-link>
        </v-card-title>

        <v-row class="auth-row ma-0">
          <v-col
            lg="12"
            class="d-flex align-center auth-bg  pb-0"
          >
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="6"
                lg="12"
                class="mx-auto"
              >
                <v-card flat>
                  <v-card-text>

                  </v-card-text>
                  <v-card-text>
                    <div class="text-center" v-if="loading">
                      <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <v-form
                      v-else
                      ref="form"
                      @submit.prevent="handleFormSubmit"
                    >
                      <v-text-field
                        v-model="email"
                        outlined
                        label="E-Mail"
                        placeholder="E-Mail"
                        :error="(errorMessages.email != undefined)"
                        :error-messages="errorMessages.email"
                        :rules="[validators.required, validators.emailValidator]"
                        hide-details="auto"
                        class="mb-6"
                      ></v-text-field>
                      <v-text-field
                        v-model="passwort"
                        outlined
                        :type="isPasswordVisible ? 'text' : 'password'"
                        label="Passwort"
                        placeholder="Passwort"
                        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                        :error="errorMessages.passwort != undefined"
                        :error-messages="errorMessages.passwort"
                        :rules="[validators.required]"
                        hide-details="auto"
                        class="mb-2"
                        @click:append="isPasswordVisible = !isPasswordVisible"
                      ></v-text-field>
                      <div class="d-flex align-center justify-space-between flex-wrap">
                        <router-link
                          :to="{name:'passwort_vergessen'}"
                          class="ms-3"
                        >
                          Passwort vergessen?
                        </router-link>
                      </div>
                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        class="mt-6"
                      >
                        Login
                      </v-btn>
                    </v-form>
                  </v-card-text>
                  <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                    <p class="mb-0 me-2">
                      Neu bei uns?
                    </p>
                    <router-link :to="{name:'auth-register'}">
                      Erstellen Sie einen Account
                    </router-link>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

    </div>
  </div>
</template>

<script>
import {mdiEyeOutline, mdiEyeOffOutline} from '@mdi/js'
import {ref, getCurrentInstance} from '@vue/composition-api'
import {required, emailValidator} from '@core/utils/validation'
import axios from '@axios'
import {useRouter} from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  setup(props, context){
    const form = ref(null)
    const loading = ref(false)
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const {router} = useRouter()
    const isPasswordVisible = ref(false)
    const email = ref('')
    const passwort = ref('')
    const errorMessages = ref([])
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()
      if(!isFormValid) return
      loading.value = true;
      axios
        .post('/api/login/', {
          aktion: 'loginFormCheck',
          userData: {
            email: email.value,
            passwort: passwort.value
          }
        })
        .then(response => {
          if(response.data.loginState === true){
            const {user} = response.data
            const {ability: userAbility} = user
            vm.$ability.update(userAbility)
            localStorage.setItem('userAbility', JSON.stringify(userAbility))
            delete user.ability
            localStorage.setItem('userData', JSON.stringify(user))

            router.push('/',()=>{
              vm.$toastr.s(response.data.message.text, response.data.message.title);
            })
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);

              //errorMessages[item.errorField].value = item.errorTitle;
            })
            errorMessages.value = response.data.errors;
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.s(error,error);
          errorMessages.value = error
          loading.value = true;
        })
    }

    return {
      form,
      handleFormSubmit,
      isPasswordVisible,
      email,
      passwort,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      loading
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
